<template>
  <div class="ecom-card-cont container">
    <div class="flex row-wrapper-reverse">
      <div class="left-side content-wrapper">
        <div class="font-large text-spacer">Intricate Modeling</div>
        <div class="font-x-small">We sweat the details, so you don’t have to.</div>
      </div>
      <div class="right-side wider-side">
        <div class="product-wrapper">
          <div class="single-wrapper">
            <div class="product-config-wrapper">
              <img
                id="image-player-patio-ar"
               v-if="currentSubItem != null" :src="currentSubItem.coverart"
              />
              <div class="ico-zoom-circle-black clickable"></div>
            </div>
            <div class="camera-options">
                <div id="" class="thumbnail-container">
                  <img id="" class="clickable texture-thumbnail camera-option" :class="{ active : subItemActiveClass == index }" v-for="(item , index) in card" @click="subItemClicked(index)" :src="item.thumbnail">
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.left-side {
    float:left;
    width:40%;
}

.right-side {
    float:right;
    width:60%;
}

.content-wrapper{
    padding-top: 30%;
    font-family:lato;
    padding-left: 40px;
}

.text-spacer {
    margin-bottom: 8px!important;
}

.font-large {
    font-size: 34px;
    line-height: 36px;
    font-weight: 500;
}


.product-wrapper{
    display: flex;
    position: relative;
    padding-top: 2%;
    width: 100%;
    top: 3px;
    float:right;
}

.camera-options{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 15px 0;
}

.single-wrapper{
    position: relative;
    width: 100%;
    margin: auto;
}

.texture-thumbnail {
    background-size: cover;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
}


.clickable {
    cursor: pointer;
}


.active{
    border: 5px solid #6b6fe7;
}



a, article, body, button, div, fieldset, footer, form, h1, h2, header, html, i, img, input, label, li, main, nav, p, section, select, small, span, strong, textarea, time, ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    font-family: lato,sans-serif;
    color: inherit;
    text-decoration: inherit;
    vertical-align: inherit;
    background: transparent;
    text-shadow: none;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.font-x-small {
    font-size: 20px;
}

.font-x-small {
    line-height: 28px;
    font-weight: 500;
}

.container div {
    color: #333;
}
</style>


<script>
  export default {
    name: 'EcomCard2',
    filters: {},
    created() {
      var Data = {
        url : this.$root.configurl
      }
      this.$store.dispatch('getConfigitems', Data).then((res) => {
        this.card = res.gallerylarge;
        this.initIndex();
      });
    },
    data: () => ({
      card: null,
      currentCard: null,
      currentSubItem: null,
      currentSubItems: null,
      currentParentIndex: 0,
      currentChildIndex: 0,
      currentitemIndex: 0,
      parentActiveClass: 0,
      childActiveClass: 0,
      subItemActiveClass: 0,
    }),
    computed: {},
    methods: {
      initIndex() {
        this.currentParentIndex = 0;
        this.currentChildIndex = 0;
        this.currentitemIndex = 0;
        this.parentClicked(0);
      },
      parentClicked(index) {
        this.currentParentIndex = index;
        this.currentCard = this.card[this.currentParentIndex];
        this.currentSubItems = this.card;      
        this.currentSubItem = this.currentSubItems[0];
        this.parentActiveClass = index;
        this.childActiveClass = 0;
        this.subItemActiveClass = 0;
      },
      subItemClicked(index) {
        this.currentSubItems = this.card;
        console.log(this.currentSubItems)
        this.currentSubItem = this.currentSubItems[index];
        console.log(this.currentSubItem)
        this.subItemActiveClass = index;
      }
    }
  } 
  </script>
