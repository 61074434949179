import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters,
  mutations,
  actions: {
    getConfigitems({state}, payload) {
        return new Promise((resolve, reject) => {
           axios.get(payload.url).then((response) => {
            resolve(response.data)
          }).catch(() => {
            reject()
          })
        })
    }
  }
})