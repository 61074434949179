<template>
  <div id="ecom-widget-app">
        <link rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
        integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
        crossorigin="anonymous">
   <EcomCard v-if="$root.viewtype == 'listview'"/> 
   <EcomCard2 v-if="$root.viewtype == 'listview2'"/> 
  </div>
</template>

<script>
import EcomCard from '@/components/EcomCard.vue'
import EcomCard2 from '@/components/EcomCard2.vue'

export default {
  props: {
    viewtype: {
      type: String,
      default: () => ({})
    },
    configurl : {
      type: String,
      default: () => ('https://s3.amazonaws.com/appdashweb/html5-templates/app-assets/webcat/widget/config.json')
    }
  },
  components: {
    EcomCard,
    EcomCard2
  },
  data () {
    return {
    }
  },
  created () {
  }
}
</script>
