<template>
   <div>
      <div class="ecom-widget-ecom-card-cont container">
         <div class="ecom-widget-parent-cont">
            <div class="ecom-widget-content-loader">
               <div class="ecom-widget-product-wrapper">
                     <img id="image-player" class="ecom-widget-image-player" v-if="currentSubItem != null" :src="currentSubItem.coverart">
               </div>
               <div id="" class="ecom-widget-thumbnail-container">
                  <img id="" class="ecom-widget-thumbnails-img" :class="{ active : subItemActiveClass == index }" v-for="(item , index) in currentSubItems" @click="subItemClicked(index)" :src="item.thumbnail">
               </div>
            </div>
            <div class="ecom-widget-content-config">
               <div class="ecom-widget-responsive-container">
                  <div class="ecom-widget-product-wrapper-config">
                     <div class="ecom-widget-config_inner-container">
                        <div class="ecom-widget-segment-container">
                           <div class="ecom-widget-header-wrapper">
                              <div class="ecom-widget-config-header-1">Base </div>
                           </div>
                           <div class="ecom-widget-material-wrapper_m">
                             <div v-for="(item, index) in card" @click="parentClicked(index)" :class="{ active : parentActiveClass == index }" class="ecom-widget-material-text">
                                {{ item.title }}
                             </div>
                           </div>
                        </div>
                        <div id="" class="ecom-widget-segment-container" data-name="Upholstery">
                           <div id="" class="ecom-widget-header-wrapper">
                              <div id="" class="ecom-widget-config-header-1">Upholstery </div>
                           </div>
                           <div id="" class="ecom-widget-material-wrapper_m">
                              <div id="" class="ecom-widget-material-image ecom-widget-toolt" :class="{ active : childActiveClass == index }" v-for="(item,index) in currentCard" @click="childClicked(index)" data-name="material"
                                 :style="{'background-image': 'url(' + item.thumbnail + ')'}">
                                 <span class="ecom-widget-tooltiptext">{{item.title}}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<style scoped>

.ecom-widget-fade-enter-active, .ecom-widget-fade-leave-active {
  transition: opacity .5s;
}
.ecom-widget-fade-enter, .ecom-widget-fade-leave-to /* .ecom-widget-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.ecom-widget-ecom-card-cont  a, article, body, button, div, fieldset, footer, form, h1, h2, header, html, i, img, input, label, li, main, nav, p, section, select, small, span, strong, textarea, time, ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    color: inherit;
    text-decoration: inherit;
    vertical-align: inherit;
    background: transparent;
    text-shadow: none;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
.ecom-widget-ecom-card-cont{
  margin: 0 auto;
    padding: 24px;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    box-shadow: 0px 5px 20px #F2F2F2;
    border-radius: 8px;
}
.ecom-widget-parent-cont{
    display: flex;
    margin: 0 auto;
    padding: 24px;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    box-shadow: 0px 5px 20px #F2F2F2;
    border-radius: 8px;
    border: 0;
    outline: 0;
    font: inherit;
    color: inherit;
    text-decoration: inherit;
    vertical-align: inherit;
    background: transparent;
    text-shadow: none;
}
.ecom-widget-content-loader{
  width: 50%;
  position: relative;
}
.ecom-widget-content-config{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 30px;
    box-sizing: border-box;
   position: relative;
}

.ecom-widget-product-wrapper{
  display: flex;
    position: relative;
    padding-top: 100%;
    width: 100%;
    top: 3px;
}
.ecom-widget-product-wrapper img {
    position: absolute;
    top: 0px;
    bottom: 0px;
    height: auto;
    width: 100%;
    margin: auto;
    left: 0px;
    right: 0px;
}
.ecom-widget-thumbnail-container{
  display: flex;
    width: 100%;
    justify-content: center;
    margin: 15px 0px;
    flex-wrap: wrap;
}
.ecom-widget-thumbnail-container img{
   background-size: contain;
    width: 58px;
    height: 58px;
    border: 1px solid white;
    /* background-color: black; */
    padding: 1px;
    margin: 0px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
.ecom-widget-thumbnails-img.active{
   border: 1px solid black;
    border-radius: 2px;
} 
.ecom-widget-responsive-container{
        width: 100%;
    box-sizing: content-box;
}
.ecom-widget-content-config .product-wrapper-config{
    display: flex;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 70px;
}
.ecom-widget-content-config .ecom-widget-product-wrapper-config .ecom-widget-config_inner-container{
  width: 100%;
    margin: 1%;
}

.ecom-widget-segment-container{
  display: flex;
  flex-direction: column;
}
.ecom-widget-header-wrapper{
  display: flex;
    flex-wrap: wrap;  
}
.ecom-widget-config-header-1{
    margin-right: 24px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 18px;
    border-bottom: 0.5px solid #BDBDBD;
    width: 100%;
    padding-bottom: 8px;
}
.ecom-widget-material-wrapper_m{
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
}

.ecom-widget-material-text{
  padding: 10px 16px;
    margin: 4px;
    border: 1px solid #333333;
    border-radius: 2px;
    cursor: pointer;
}
.ecom-widget-material-text.active{
  font-weight: bold;
  color: white;
  background-color: #333333;
}
.ecom-widget-material-wrapper_m .ecom-widget-material-image{
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    margin: 4px;
    padding: 1px;
    border: 1px solid white;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
}
.ecom-widget-material-wrapper_m .ecom-widget-material-image.active{
  border: 1px solid black;
    border-radius: 2px;
}
.ecom-widget-baseitem.active{
  color: #FFF;
  border-radius: 3px;
  background-color: #000;
  border: 2px solid #000;
}
.ecom-widget-toolt {
}
.ecom-widget-toolt .ecom-widget-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.ecom-widget-toolt .ecom-widget-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.ecom-widget-toolt:hover .ecom-widget-tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 600px) {

  .ecom-widget-ecom-card-cont {
    padding: 16px;
  }
  .ecom-widget-content-loader{
    width: 100%;
  }
  .ecom-widget-content-config{
    width: 100%;
    padding-left: 0px;
  }
  .ecom-widget-content-config .product-wrapper-config{

    padding-top: 5px;
  }
}
</style>
<script>
  export default {
    name: 'EcomCard',
    filters: {},
    created() {
      var Data = {
        url : this.$root.configurl
      }
      this.$store.dispatch('getConfigitems', Data).then((res) => {
        this.card = res.gallerylarge;
        this.initIndex();
      });
    },
    data: () => ({
      card: null,
      currentCard: null,
      currentSubItem: null,
      currentSubItems: null,
      currentParentIndex: 0,
      currentChildIndex: 0,
      currentitemIndex: 0,
      parentActiveClass: 0,
      childActiveClass: 0,
      subItemActiveClass: 0,
    }),
    computed: {},
    methods: {
      initIndex() {
        this.currentParentIndex = 0;
        this.currentChildIndex = 0;
        this.currentitemIndex = 0;
        this.parentClicked(0);

      },
      parentClicked(index) {
        this.currentParentIndex = index;
        this.currentCard = this.card[this.currentParentIndex].items;
        this.currentSubItems = this.currentCard[0].subitems;
        this.currentSubItem = this.currentSubItems[0];
        this.parentActiveClass = index;
        this.childActiveClass = 0;
        this.subItemActiveClass = 0;
      },
      childClicked(index) {
        this.currentSubItems = this.currentCard[index].subitems;
        this.currentSubItem = this.currentSubItems[0];
        this.childActiveClass = index;
        this.subItemActiveClass = 0;
      },
      subItemClicked(index) {
        this.currentSubItem = this.currentSubItems[index];
        this.subItemActiveClass = index;
      }
    }
  } 
  </script>